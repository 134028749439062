import logo from './logo.svg';
import './App.css';
import FilerobotImageEditor, {TABS, TOOLS} from "react-filerobot-image-editor";
import {useState} from "react";

function App() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [company, setCompany] = useState('');
    const [renderedImg, setRenderedImg] = useState('');

    const [image, setImage] = useState('https://scaleflex.airstore.io/demo/stephen-walker-unsplash.jpg');

    const saveState = (state) => {
        localStorage.setItem('imageState', JSON.stringify(state));
    }

    const getState = () => {
        return JSON.parse(localStorage.getItem('imageState'));
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const renderImage = async () => {
        const state = getState();

        const result = await fetch('/app/image-build', {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            // mode: "cors", // no-cors, *cors, same-origin
            // cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            // credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json",
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            // redirect: "follow", // manual, *follow, error
            // referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify({state: state, replaceText: {firstName: firstName, lastName: lastName, company: company}}), // body data type must match "Content-Type" header
        });

        result.text().then(res => {
            setRenderedImg(res);
        });
    }

    const base64Image = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    }

    // const a = JSON.parse(localStorage.getItem('imageState'));
    // a.designLayer = JSON.parse(a.designLayer);
    // a.previewGroup = JSON.parse(a.previewGroup);
    //

  return (
    <div className="App">
        <div style={{maxWidth: '600px'}}>
            <input type={'file'} onChange={async (e) => {
                setImage(await base64Image(e.target.files[0]));
            }}/>
            <FilerobotImageEditor
                source={image}
                // onModify={(editedImageObject, designState) => {
                //     console.log(editedImageObject);
                //     saveState(editedImageObject)
                // }}
                onSave={(editedImageObject, designState) => {
                    saveState(designState)
                }}
                loadableDesignState={JSON.parse(localStorage.getItem('imageState'))}
                annotationsCommon={{
                    fill: '#ff0000',
                }}
                Text={{ text: '' }}
                Rotate={{ angle: 90, componentType: 'slider' }}
                tabsIds={[TABS.ANNOTATE]} // or {['Adjust', 'Annotate', 'Watermark']}
                defaultTabId={TABS.ANNOTATE} // or 'Annotate'
                defaultToolId={TOOLS.TEXT} // or 'Text',
            />
            <div>
                <label>Enter firstName value </label>
                <input value={firstName} onChange={(e) => setFirstName(e.target.value)} />
            </div>
            <div>
                <label>Enter lastName value </label>
                <input value={lastName} onChange={(e) => setLastName(e.target.value)} />
            </div>
            <div>
                <label>Enter company value </label>
                <input value={company} onChange={(e) => setCompany(e.target.value)} />
            </div>
            <div>
                <button onClick={renderImage}>Render image</button>
            </div>

            <div>
                {renderedImg.length > 0 &&
                    <img src={'data:image/png;base64,' + renderedImg} />
                }
            </div>
        </div>
    </div>
  );
}

export default App;
